import logo from "./Relevant.svg";
import "./App.css";

function App() {
  // Redirect immediately
  window.location.replace(
    "https://forms.zohopublic.com/relevantlocalmedia/form/NewOnboardingCustomer/formperma/lejfBZYh9d4AhKnAxyUdJyfy6jahTOZ-6g-Mt8klMQ4"
  );

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
